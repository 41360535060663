export const SessionConfig = {
  postNewSession: "/Session/PostNewSession",
  postNewSessionAsOrganizer: "/Session/NominateNewSession",
  getSessionByEventId: "/Event/GetSessionsByEventId",
  GetSessionByStatus :"/Session/GetSessionByStatus", 
  GetSessionById: "/Session/GetSessionById",
  GetSessionsByUserId: "/Session/GetSessionsByUserId",
  updateSession: "/Session/UpdateSession",
  sessionConfirm: "/Session/AcceptSession",
  updateAgendaBuilder : "/Session/UpdateAgendaBuilder",
  updateStatus: "/Session/UpdateSessionStatus",
  withdrawal: "/Session/WithdrawSession",
};