import * as XLSX from 'xlsx';

export const exportToExcel = (data, filename, sheetname) => {

    // Create a new worksheet with the selected fields
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetname);

    // Generate the Excel file and trigger download
    XLSX.writeFile(workbook, filename+'.xlsx');
  };