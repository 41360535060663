import { Routes, Route } from "react-router-dom";
import SignIn from "./pages/SigIn/sigin";
import NotFound from "./components/NotFound/NotFound";
import Layout from "./layout/WebsiteLayout/Layout";
import CreateEvent from "./components/Admin/CreateEvent/CreateEvent";
import ListEvents from "./components/Admin/ListEvents/ListEvents";
import EditEvent from "./components/Admin/EditEvent/EditEvent";
import ListSessionsComponent from "./components/Admin/Session/SessionList/SessionList";
import EventList from "./components/Admin/EventList/EventList";
import AuthCallback from "./pages/AuthCallback/AuthCallback";
import PopupHandler from "./pages/PopupHandler/PopupHandler";
import Register from "./pages/Register/register";
import ConsentForm from "./pages/ConsentForm/ConsentForm";
import Auth from "./pages/AuthCallback/Auth";
import { Role } from "./enums/Roles";
import ForgetPassword from "./pages/ForgetPassword/forgetPassword";
import Organizer from "./components/Admin/Organizer/Organizer";
import Dashboard from "./components/Admin/EventDashboard/EventDashboard";
import SessionList from "./components/Admin/Session/SessionList/SessionList";
import DetailSession from "./components/Admin/Session/DetailSession/DetailSession";
import SpeakerList from "./components/Admin/Speaker/SpeakerList/SpeakerList";
import DetailSpeaker from "./components/Admin/Speaker/DetailSpeaker/DetailSpeaker";
import Dnd from "./components/Admin/DragandDrop/Dnd";
import DndList from "./components/Admin/DragandDrop/Dnd";
import DndCalendar from "./components/Admin/DragandDrop/Dnd";
import CfsPage from "./components/Common/CFSPage/CFSPage";
import UserProfile from "./components/User/UserProfile/UserProfile";
import { SpeakerInvite } from "./components/Admin/SpeakerInvite/SpeakerInvite";
import SpeakerSessionList from "./components/User/SessionList/SpeakerSessionList";
import SessionDetailComponent from "./components/User/SessionDetails/SessionDetails";
import NominateAsOrganizer from "./components/Admin/Session/NominateAsOrganizer/NominateAsOrganizer";
import SessionConfirm from "./components/User/SessionConfirm/SessionConfirm";
const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/home" element={<Layout />} />
      <Route path="/" element={<Layout />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/register" element={<Register />} />
      <Route
        path="/forget-password"
        element={<ForgetPassword toggleSignup={undefined} />}
      />
      <Route path="/consent-form" element={<ConsentForm />} />
      <Route path="/popuphandler" element={<PopupHandler />} />
      <Route path="/list-events" element={<ListEvents />} />
      <Route path="/list-events/:eventId" element={<ListSessionsComponent />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/cfs/:slugUrl" element={<CfsPage />} />
      <Route path="/accept/:sessionId" element={<SessionConfirm />} />

      <Route element={<Auth allowedRoles={[Role.Admin]} />}>
        <Route path="/organizer/*" element={<Organizer />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="organizer/edit-event/:eventId" element={<EditEvent />} />
          <Route path="agenda-builder" element={<Dnd />} />
          <Route
            path="nominate-as-organizer"
            element={<NominateAsOrganizer />}
          />
          <Route path="organizer/session-list" element={<SessionList />} />
          <Route path="session-list/:sessionId" element={<DetailSession />} />
          <Route path="speaker-list" element={<SpeakerList />} />
          <Route path="speaker-list/:speakerId" element={<DetailSpeaker />} />
        </Route>
      </Route>

      <Route element={<Auth allowedRoles={[Role.Admin]} />}>
        {/* <Route path="/organizer/edit-event/:eventId" element={<EditEvent />} /> */}
        <Route path="/organizer/event-creation" element={<CreateEvent />} />
        <Route path="/organizer/event-list" element={<EventList />} />
      </Route>

      <Route element={<Auth allowedRoles={[Role.Admin, Role.User]} />}>
        <Route path="/speaker/user-profile" element={<UserProfile />} />
        <Route path="/speaker/my-events" element={<SpeakerSessionList />} />
        <Route
          path="/speaker/my-events/:sessionId"
          element={<SessionDetailComponent />}
        />
        <Route
          path="/speaker/invitation/:inviteLink"
          element={<SpeakerInvite />}
        />
      </Route>

      {/* <Route path="session-list" element={<SessionList />} />
      <Route path="session-list/:sessionId" element={<DetailSession />} />
      <Route path="speaker-list" element={<SpeakerList />} />
      <Route path="speaker-list/:speakerId" element={<DetailSpeaker />} /> */}
    </Routes>
  );
};

export default AppRoutes;
