export function formatDate(isoString: string): string {
    const date = new Date(isoString);
  
    // Correct options for date formatting
    const optionsDate: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    };
  
    // Correct options for time formatting
    const optionsTime: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
  
    const formattedDate = date.toLocaleDateString('en-GB', optionsDate);
    const formattedTime = date.toLocaleTimeString('en-US', optionsTime);
  
    return `${formattedDate} ${formattedTime}`;
}

export function convertUtcToIst(utcDate: string): string {
    const date = new Date(utcDate);

    // Add 5 hours and 30 minutes to the UTC time for IST
    const istOffset = 5 * 60 + 30; // IST is UTC +5:30 in minutes
    const istDate = new Date(date.getTime() + istOffset * 60 * 1000);

    return istDate.toISOString();
}