import moment from "moment";
import { convertUtcToIst } from "../../../helper/DateFormatter";

export type EventItem = {
  id: any;
  start?: Date | string;
  end?: Date | string;
  data?: { appointment?: Appointment };
  isDraggable?: boolean;
  isResizable?: boolean;
  resourceId?: number;
  room?: string;
};

export type Appointment = {
  description: any;
  sessionType: any;
  id: number;
  title: string;
  status: string;
  location: string;
  resource: string;
  address: string;
};

export enum AppointmentStatusCode {
  Pending = "P",
  CheckedIn = "CI",
}

export const EVENT_STATUS_COLORS = {
  P: "#bee2fa",
  CI: "#c7edca",
};

export const EVENTS = [
  {
    start: moment("2024-07-16T09:00:00").toDate(),
    end: moment("2024-07-16T10:00:00").toDate(),
    data: {
      appointment: {
        id: 1,
        status: "P",
        location: "New York",
        resource: "Dr Alex",
        address: "Building 5\nStreet 44\nNear Express Highway\nNew York",
        description: "Some description",
        sessionType: 1,
      },
    },
    isDraggable: true,
    isResizable: true,
    resourceId: 1,
  },
  {
    start: moment("2024-07-16T10:00:00").toDate(),
    end: moment("2024-07-16T11:00:00").toDate(),
    data: {
      appointment: {
        id: 2,
        status: "CI",
        location: "Washington",
        resource: "Dr David",
        address: "Block 1\nSStreet 32\nLong Island\nNew York",
        description: "Some description",
        sessionType: 2,
      },
    },
    isDraggable: true,
    isResizable: true,
    resourceId: 2,
  },
];

export const getBetweenDates = (startDate: string, endDate: string): string[] => {
  console.log(startDate);
  console.log(endDate);
  const start = moment(startDate);
  const end = moment(endDate);

  const dates: string[] = [];

  while (start.isSameOrBefore(end)) {
    dates.push(start.format('YYYY-MM-DD'));
    start.add(1, 'day');
  }

  console.log(dates);
  return dates;
};

export const convertToSessionUpdateModel = (events)=>{
  var sessionList = [];
  console.log(events);
  events.forEach(draggedEvent => {
      const updatedEvent = {
        id: draggedEvent.id,
        startsAt : convertUtcToIst(draggedEvent.start),
        endsAt : convertUtcToIst(draggedEvent.end),
        resourceId: draggedEvent.resource || 1,
        location: draggedEvent.location,
        resource: draggedEvent.resource,
        description: draggedEvent.description,
        sessionType: "1",
        room: draggedEvent.resourceId ? draggedEvent.resourceId.toString() : "",
      };
      sessionList.push(updatedEvent);
  });
  return sessionList;
}
