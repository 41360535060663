import React, { useCallback, useEffect, useState } from "react";
import "tailwindcss/tailwind.css";
import { Calendar as BigCalendar, stringOrDate } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { convertToSessionUpdateModel, EventItem, getBetweenDates } from "./DndHelper"; // Update as per your EventItem structure
import AppointmentEvent from "./Appointment";
import EventItemComponent from "./EventItemComponent";
import SessionsService from "../../../services/sessionService";
import moment from "moment"; // Import moment for date handling
import { generateCalendarProps } from "./Props";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import EventService from "../../../services/eventService";
import { SessionStatus } from "../../../enums/SessionStatus";
import { HashLoader } from "react-spinners";

const DndList = () => {
  const [events, setEvents] = useState<EventItem[]>([]);
  const [eventDetails, setEventDetails] = useState<any>({});
  const [appointments, setAppointments] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [draggedEvent, setDraggedEvent] = useState<any>();
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const { eventId } = useParams<{ eventId: string }>();
  const [dateArray, setDateArray] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [eventsByDate, setEventsByDate] = useState([]);

  useEffect(() => {
    getEventsbyEventId(eventId);
    getSessionsByEventId(eventId);
    handleDateClick(selectedDate);
  }, [eventId]);

  const getEventsbyEventId = (eventId: string) => {
    setLoading(true);
    EventService.getEventbyId(eventId).then((resdata) => {
      if (resdata && resdata.data) {
        setEventDetails(resdata.data);
        const dates = getBetweenDates(
          resdata.data?.eventStartDate,
          resdata.data?.eventEndDate
        );
        setDateArray(dates);
        setSelectedDate(dates.find((e) => !!e));
      }
    });
  };

  const getSessionsByEventId = (eventId: string) => {
    SessionsService.getSessionByStatus(eventId, SessionStatus.Accepted)
      .then((resData) => {
        if (resData && resData.data) {
          // Process the fetched data and update events and appointments
          const updatedEvents: EventItem[] = [];
          const updatedAppointments: any[] = [];

          resData.data.forEach((session) => {

            console.log('Sessions', session);
            if (session.startsAt && session.endsAt) {
              // Convert startsAt and endsAt to Date objects using moment
              const start = moment(session.startsAt).toDate();
              const end = moment(session.endsAt).toDate();

              // Determine resourceId dynamically based on session data (example)
              const resourceId = parseInt(session.room, 10) || 1; // Default to 1 if room is not a number

              // Create new event object
              const newEvent: EventItem = {
                id: session.id,
                start,
                end,
                data: {
                  appointment: {
                    id: session.id,
                    status: session.sessionStatus === 0 ? "P" : "CI",
                    location: session.room || "",
                    title: session.title,
                    resource: session.title,
                    address: session.room ? `Room: ${session.room}` : "",
                    description: session.description,
                    sessionType: "1",
                  },
                },
                isDraggable: true,
                isResizable: true,
                resourceId,
                room: session.room, // Save room as room number
              };

              updatedEvents.push(newEvent);
            } else {
              // If startsAt or endsAt is empty, add to appointments
              updatedAppointments.push({
                id: session.id,
                status: session.sessionStatus === 0 ? "P" : "CI",
                location: session.room || "",
                resource: session.title ,
                address: session.room ? `Room: ${session.room}` : "",
              });
            }
          });

          // Update state with new events and appointments
          setEvents(updatedEvents);
          console.log(updatedEvents);
          setAppointments(updatedAppointments);
        }
      })
      .catch((err) => {
        //console.error("Error fetching sessions:", err);
      });
      setLoading(false);
  };

  // const onUpdateSession = async (data: any) => {
  //   try {
  //     const sessionDetails = {
  //       id: data.id,
  //       startsAt: moment(data.start).format("YYYY-MM-DDTHH:mm"),
  //       endsAt: moment(data.end).format("YYYY-MM-DDTHH:mm"),
  //       room: data.room, // Use room number as resourceId
  //       title: data.resource,
  //       description: data.description,
  //       sessionType: "1",
  //     };
  //     setLoading(true);
  //     const sessionUpdated = await SessionsService.sessionTimeUpdate(
  //       sessionDetails
  //     );
  //     if (sessionUpdated) {
  //       setLoading(false);
  //       toast.success("Session Updated Successfully", { autoClose: 2000 });
  //       getSessionsByEventId(eventId);
  //     } else {
  //       setLoading(false);
  //       toast.error("Something went wrong!", { autoClose: 10000 });
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     //console.error("Error updating session:", error);
  //   }
  // };

  const onChangeEventTime = useCallback(
    ({
      event,
      start,
      end,
      resourceId,
    }: {
      event: EventItem;
      start: stringOrDate;
      end: stringOrDate;
      resourceId: number;
    }) => {
      setEvents((prevEvents) =>
        prevEvents.map((prevEvent) =>
          prevEvent?.data?.appointment?.id === event?.data?.appointment?.id
            ? {
                ...event,
                start,
                end,
                resourceId,
                room: resourceId.toString(), // Update room with resourceId
              }
            : prevEvent
        )
      );

      // const updatedEvent = {
      //   ...event,
      //   start,
      //   end,
      //   resourceId,
      //   room: resourceId.toString(), // Update room with resourceId
      //   id: event.id,
      //   location: event.data.appointment.location,
      //   resource: event.data.appointment.resource,
      //   description: event.data.appointment.description,
      //   sessionType: event.data.appointment.sessionType,
      // };

      // console.log(updatedEvent);

      // onUpdateSession(updatedEvent);
    },
    []
  );

  const onDroppedFromOutside = useCallback(
    async ({ start, end, resource }) => {
      if (!draggedEvent || draggedEvent === "undroppable") return;
      const newEvent = {
        id: draggedEvent.id,
        start : start,
        end :end,
        room: resource ? resource.toString() : "",
        data: { appointment: draggedEvent },
        isDraggable: true,
        isResizable: true,
        resourceId: resource,
      };

      setEvents((prevEvents) => [...prevEvents, newEvent]);

      // Update session time via API call
      // const updatedEvent = {
      //   id: draggedEvent.id,
      //   start,
      //   end,
      //   resourceId: resource || 1, // Default to 1 if resourceId is undefined
      //   location: draggedEvent.location,
      //   resource: draggedEvent.resource,
      //   description: draggedEvent.description,
      //   sessionType: "1",
      //   room: resource ? resource.toString() : "",
      // };

      // await onUpdateSession(updatedEvent);
      handleDrop();
    },
    [draggedEvent]
  );

  const handleDragStart = (event: EventItem) => {
    setDraggedEvent(event?.data?.appointment);
    setIsDragging(true);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    setDraggedEvent(undefined);
  };

  const handleDropOnAppointmentArea = (
    e: React.DragEvent<HTMLUListElement>
  ) => {
    e.preventDefault();
    if (isDragging && draggedEvent !== "undroppable") {
      setEvents((prevEvents) =>
        prevEvents.filter(
          (event) => event.data.appointment.id !== draggedEvent.id
        )
      );
      setAppointments((prevAppointments) => [
        ...prevAppointments,
        draggedEvent,
      ]);
    }
    setIsDragging(false);
    setDraggedEvent(undefined);
  };

  const handleDrop = () => {
    setAppointments((prevAppointments) =>
      prevAppointments.filter((appointment) => appointment !== draggedEvent)
    );
    setDraggedEvent(null);
  };

  const handleDeleteEvent = async (event: EventItem) => {
    try {
      // Update session with null dates via API call
      const nullDates = [{
        id: event.id,
        startsAt: null,
        endsAt: null,
        room: null,
        title: event.data.appointment.resource,
        description: event.data.appointment.description,
        sessionType: event.data.appointment.sessionType,
      }];

      setLoading(true);
      const sessionDeleted = await SessionsService.updateAgendaBuilder(nullDates);
      if (sessionDeleted) {
        setLoading(false);
        toast.success("Session Deleted Successfully", { autoClose: 2000 });

        // Create appointment object with null dates
        const appointment = {
          ...event.data.appointment,
          startsAt: null,
          endsAt: null,
        };

        // Move the deleted event to appointments list
        setAppointments((prevAppointments) => [
          ...prevAppointments,
          appointment,
        ]);

        // Remove the event from events list
        setEvents((prevEvents) =>
          prevEvents.filter((prevEvent) => prevEvent !== event)
        );
      } else {
        setLoading(false);
        toast.error("Something went wrong!", { autoClose: 10000 });
      }
    } catch (error) {
      setLoading(false);
      //console.error("Error deleting session:", error);
      toast.error("Error deleting session. Please try again later.");
    }
  };

  const resources = [
    { id: 1, title: "Track 1" },
    { id: 2, title: "Track 2" },
    { id: 3, title: "Track 3" },
    { id: 4, title: "Track 4" },
    { id: 5, title: "Track 5" },
  ];

  const saveChanges = async () =>{
    var sessionList = convertToSessionUpdateModel(events);
    const sessionUpdated = await SessionsService.updateAgendaBuilder(
      sessionList
    );
  }

  const handleDateClick = (date) => {
    //console.log("Selected Date:", date);

    // Normalize the selected date to the start of the day in UTC
    const selectedDateFormatted = moment
      .utc(date)
      .startOf("day")
      .toLocaleString();

    //console.log("Formatted Selected Date:", selectedDateFormatted);

    // Filter events based on the normalized date
    const filteredEvents = events.filter((event) => {
      // Normalize the event start time to the start of the day in UTC
      const eventStart = moment
        .utc(event.start)
        .startOf("day")
        .toLocaleString();
      return eventStart === selectedDateFormatted;
    });

    //console.log("All Events:", events);
    //console.log("Filtered Events:", filteredEvents);

    setEventsByDate({ ...eventsByDate, [date]: filteredEvents });
    setSelectedDate(date);
  };

  useEffect(() => {
    if (selectedDate) {
      handleDateClick(selectedDate);
    }
  }, [events]);

  const DnDCalendar = withDragAndDrop<EventItem>(BigCalendar);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 space-y-4 w-full">
      {loading && (
        <div className="absolute inset-0 bg-black bg-opacity-80 z-10">
          <div
            role="status"
            className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
          >
            <HashLoader color={"white"} loading={loading} size={50} />
          </div>
        </div>
      )}
      <div className="flex p-2 gap-4 h-full w-full flex-row">
        <div className="p-4 bg-white w-[20%] overflow-y-scroll">
          <h2 className="text-lg font-semibold mb-4">Session List</h2>
          <ul onDrop={handleDropOnAppointmentArea} className="text-left">
            {appointments.map((appointment, index) => (
              <li
                key={index}
                className="p-2 mb-2 border rounded shadow text-left"
                onDragStart={() => setDraggedEvent(appointment)}
                draggable
              >
                <AppointmentEvent appointment={appointment} />
              </li>
            ))}
          </ul>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex flex-row m-2 justify-end">
            <div className="flex flex-row justify-between">
              <button
                type="button"
                onClick={()=>saveChanges()}
                className="inline-block rounded bg-primary px-6 pb-2 pt-2.5 mr-2 text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 motion-reduce:transition-none dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong bg-blue-700"
              >
                Save changes
              </button>
              <button
                type="button"
                onClick={()=>window.location.reload()}
                className="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 motion-reduce:transition-none dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong bg-red-400"
              >
                Discard
              </button>
            </div>
          </div>
          <div className="flex flex-row my-3">
            <div className="flex">
              <div className="flex bg-gray-100  p-1 transition dark:bg-neutral-200">
                {dateArray.map((date, index) => {
                  return (
                    <nav
                      className="flex"
                      aria-label="Tabs"
                      role="tablist"
                      aria-orientation="horizontal"
                      key={index}
                    >
                      <button
                        type="button"
                        onClick={() => handleDateClick(date)}
                        className={`py-2 px-4 inline-flex items-center gap-x-2 
                        text-sm text-gray-500 hover:text-gray-700 
                        focus:outline-none font-medium  transition 
                        ${
                          selectedDate === date
                            ? "bg-white text-gray-700 shadow"
                            : "bg-gray-100 text-gray-500 hover:bg-gray-200 hover:text-gray-700"
                        }`}
                        id={`segment-item-${index}`}
                        aria-selected={selectedDate === date}
                        aria-controls={`segment-${index}`}
                        role="tab"
                      >
                        {moment(date).format("DD-MM-YYYY")}
                      </button>
                    </nav>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="flex-1 overflow-auto w-full">
            {selectedDate && (
              <DnDCalendar
                events={eventsByDate[selectedDate]}
                toolbar={false}
                views={{
                  day: true,
                }}
                // {...props}
                {...generateCalendarProps(selectedDate)}
                resources={resources}
                onEventDrop={onChangeEventTime}
                onEventResize={onChangeEventTime}
                onDropFromOutside={onDroppedFromOutside}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                draggableAccessor={(event) => event.isDraggable}
                resizableAccessor={(event) => event.isResizable}
                components={{
                  event: ({ event }) => (
                    <EventItemComponent
                      event={event}
                      onDelete={handleDeleteEvent}
                    />
                  ),
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DndList;
